@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

* {
  font-family: "Rubik", sans-serif;
}

.expand-icon {
  color: white;
  /* Change the color as needed */
}

.prof {
  font-size: 20px;
  font-weight: bold;
  color: #1f2e39;
}

.highly {
  font-size: 14px;
  color: #1f2e39;
}

.helthcare {
  font-size: 14px;
  color: #1f2e39;
}

/* .match-dta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
} */

.p-match {
  font-size: 20px;
  font-weight: bold;
  color: #1f2e39;
  background: white;
  border: none;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.p-job {
  color: #21d6aa;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
}

.p-Rank {
  color: #1f2e39;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.fulldiv {
  /* margin-left: 15px; */
}

.match-dta2 {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.accordionsummary {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #5a93ed transparent;
  font-family: Rubik;
}
.MuiAccordionSummary-button {
  background-color: #21d6aa !important;
  border-radius: 5px !important;
}
.MuiSvgIcon-root {
  fill: white !important;
}
.buttonboxsecond {
  display: flex;
  width: 145px;
  height: 47px;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  justify-content: space-between;
  border: linear-gradient(to bottom left, #22d6aa 50%, #1dc59c 50%);
  background: linear-gradient(to bottom left, #22d6aa 50%, #1dc59c 50%);
  background-size: 150% 100%;
  background-position: 100% 0;
  clip-path: polygon(
    0 12px,
    12px 0,
    100% 0,
    100% calc(100% - 12px),
    calc(100% - 12px) 100%,
    0 100%
  );
  transition: all 0.3s ease;
}

.buttonboxsecond:hover {
  background-size: -150% -100%;
  background: linear-gradient(
    to bottom left,
    #22d6aa 0%,
    #22d6aa 50%,
    #22d6aa 50%,
    #22d6aa 100%
  );
  background-position: 100% 0;
}

.iconboxsecond {
  width: 15px;
  height: 15px;
  transition: transform 0.3s ease;
  /* Apply transition effect to icon */
  display: block;
  /* Ensure the icon is displayed as a block element */
  margin: 0 auto;
  /* Center the icon horizontally */
}

.buttonboxsecond:hover .iconboxsecond {
  /* transform: translate(-50%, -50%); */
  transform: translateX(10%);
  width: 25px;
  height: 25px;
}

.backbuttonsecond {
  color: #1f2e39;
  font-size: 13px;
  font-weight: 600;
}

.buttonboxsecond:hover .ratethismatch {
  display: none;
}

.prosandcons {
  color: #1f2e39;
  font: normal normal bold 15px/23px Rubik;
}
.highlys {
  font-size: 16px;
  color: #1f2e39;
}

.helthcares {
  font-size: 16px;
  color: #1f2e39;
}
