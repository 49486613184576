@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

* {
  font-family: "Rubik", sans-serif;
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  box-sizing: border-box;
}

.head-title {
  background: url(/public/images/Green-Box.svg);
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-repeat: no-repeat;
  border-radius: 5px;
  align-items: center;
  background-size: cover;
  padding: 9px 20px;
  margin-top: 3px;
  margin-right: 10px;
  margin-left: 10px;
  box-sizing: content-box;
}

.pro {
  font-size: 18px;
  font-weight: bold;
}

@media (min-width: 1280px) and (min-height: 720px) {
  .head-title {
    background: url(/public/images/Green-Box.svg);
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-repeat: no-repeat;
    border-radius: 5px;
    align-items: center;
    background-size: cover;
    padding: 9px 20px;
    margin-right: 10px;
    margin-left: 10px;
  }
}
.remove-arrow::-webkit-outer-spin-button,
.remove-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.remove-arrow[type=number] {
  -moz-appearance: textfield;
}
