@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

* {
  font-family: "Rubik", sans-serif;
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  box-sizing: border-box;
}

/*mobile header*/
.col-mobile {
  background: url(/public/images/Green-Box.svg);
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-repeat: no-repeat;
  align-items: center;
  background-size: cover;
  padding: 9px 10px;
}

.pro-mobile {
  font-size: 18px;
  font-weight: bold;
}

/*laptop header*/
.col {
  background: url(/public/images/Green-Box.svg);
  color: white;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  background-repeat: no-repeat;
  border-radius: 5px;
  align-items: center;
  background-size: cover;
  padding: 9px 20px;
  margin-top: 3px;
  margin-right: 10px;
  margin-left: 10px;
}

.pro {
  font-size: 18px;
  font-weight: bold;
}

.noti {
  height: 20px;
  transition: transform 0.5s ease;
}

.noti:hover {
  transform: rotate(25deg);
}

/*search box*/

/* Style the search container */
.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-side {
  flex: 1;
  /* Takes up remaining space */
}

.right-side {
  margin-left: 10px;
  /* Adjust margin as needed */
}

/* Style the search container */
.search-container {
  display: flex;
  align-items: center;
}

/* Style the search input */
.search-bar {
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 20px;
}

.search-container input[type="text"] {
  padding: 15.7px;
  border: none;
  background-color: white;
  border-radius: 5px 0px 0px 5px;
  width: 420px;
  box-shadow: 0px 0px 20px #5b93ed33;
}

.search-container button {
  background-color: #21d6aa;
  border: none;
  border-radius: 0 5px 5px 0;
  padding: 12px;
  cursor: pointer;
}

.search-container button img {
  width: 20px;
  /* Adjust icon size as needed */
}

.search-container input[type="text"]::placeholder {
  color: #c6d7e3;
  /* Placeholder color */
}

.button-container {
  display: flex;
  margin-left: 20px;
  flex-direction: row;
}

.button1,
.button2 {
  padding: 10px 10px;
  border: none;
}

.button1 {
  border-radius: 5px 0px 0px 5px;
  cursor: pointer;
}

.button2 {
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
}

/* Style the search button */

.chakraa {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 40px;
}

.full {
  display: flex;
  flex-direction: row;
  width: 100%;
}

/*table*/

.table {
  margin-top: 10px;
  margin-right: -10px;
  margin-left: -10px;
  box-shadow: 0px 0px 20px #5b93ed33;
  font-family: "Rubik, Medium";
}

.open {
  margin-top: 5px;
  font-size: 11px;
  color: #708ea4;
}

.number {
  color: #21d6aa;
  font-weight: 600;
  font-size: 15px;
}

.title {
  color: #1f2e39;
  font-weight: 500;
  font-size: 15px;
}

.raw {
  display: flex;
  color: #1f2e39;
  font-size: 15px;
}

.per {
  color: #708ea4;
  font-size: 15px;
}

.arrow {
  width: 15px;
}

.colum {
  font-weight: 600;
  max-width: 175px;
}

.box {
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: #21d6aa;
  background-repeat: no-repeat;
  position: relative;
  z-index: 99;
  clip-path: polygon(
    0 12px,
    12px 0,
    100% 0,
    100% calc(100% - 12px),
    calc(100% - 12px) 100%,
    0 100%
  );
  transition: all 0.4s ease;
  overflow: hidden;
}

#box {
  display: flex;
  flex-direction: row;
}

.icon {
  transition: transform 0.4s ease;
  /* Apply transition effect to icon */
}

.box:hover .icon {
  transform: rotate(45deg);
  /* Rotate icon on hover */
}

.text {
  display: none;
  font-weight: 600;
  font-size: 13px;
}

.box:hover {
  margin-left: -40px;
  width: 80px;
  clip-path: polygon(
    0 12px,
    12px 0,
    100% 0,
    100% calc(100% - 12px),
    calc(100% - 12px) 100%,
    0 100%
  );
}

.boxbtn-parentt {
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: flex-end;
  /* align-items: center; */
}

.box:hover .text {
  display: block;
}

.MuiDrawer-root .MuiButtonBase-root:hover {
  background-color: transparent !important;
}

/* drop down list */

.sorting-remove-table {
  font-size: 15px;
  color: "#1F2E39";
  border: none;
  padding: 5px 10px;
  box-shadow: 0px 0px 20px #5b93ed33;
}

/* Grid */

.grid {
  margin-top: 10px;
  margin-right: -10px;
  margin-left: -10px;
}

.blue {
  color: #5a93ed;
  font-size: 14px;
  font-weight: 600;
}

.gray {
  color: #708ea4;
  font-size: 10px;
}

.grid-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.under-de {
  color: #1f2e39;
  font-weight: 600;
  height: 125px;
}

.job-id {
  background: url(/public/images/job-id.svg);
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  padding: 3px 10px;
  background-repeat: no-repeat;
  border-radius: 3px 5px 0px 0px;
  margin-bottom: -1px;
}

.job-container {
  display: flex;
  justify-content: flex-end;
}

/*pagination*/

.pagination {
  display: flex;
  position: sticky;
  bottom: 0;
  background: #f3f5f9;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #1f2e39;
  cursor: pointer;
  justify-content: center;
}

.pagination div {
  color: #000;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  margin: 0 4px;
}

.pagination div.active {
  background-color: #21d6aa;
  color: white;
  border: 1px solid #21d6aa;
  border-radius: 5px;
}

.pagination div:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}

/*media query of pagination*/

.pagination-mainstick {
  position: sticky;
  bottom: 0;
  text-align: center;
}

.sort-filter-mobile {
  background-color: rgba(33, 214, 170, 1);
  display: inline-flex;
  color: white;
  padding: 15px 10px;
  border-radius: 5px;
  gap: 7px;
}

.pagination-mobile {
  display: flex;
  background: #f3f5f9;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #1f2e39;
  cursor: pointer;
  justify-content: center;
}

.pagination-mobile a {
  color: #000;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  margin: 0 4px;
}

.pagination-mobile a.active {
  background-color: #21d6aa;
  color: white;
  border: 1px solid #21d6aa;
  border-radius: 5px;
}

.pagination-mobile a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}

.checkbox-container input {
  display: none;
}

.checkbox-container {
  display: flex;
  align-items: center;
  /* Align items vertically */
  justify-content: space-between;
  /* Add space between items */
  margin-right: 20px;
}

/* Style the custom checkbox */
.checkbox-container .checkmark {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
}

/* Style the checked state */
.checkbox-container input:checked + .checkmark {
  background-color: #21d6aa;
  border: none;
}

/* Create the checkmark icon */
.checkbox-container .checkmark:after {
  content: "";
  display: none;
  width: 5px;
  height: 9px;
  margin-left: 6px;
  margin-top: 3px;
  border: solid white;
  border-width: 0 1.5px 1.5px 0;
  transform: rotate(45deg);
}

/* Show the checkmark icon when checkbox is checked */
.checkbox-container input:checked + .checkmark:after {
  display: block;
}

.lsittt {
  margin-left: 20px;
  line-height: 40px;
}

.filtersss {
  display: flex;
  justify-content: space-between;
}

.filterText {
  color: #1f2e39;
  font-size: 22px;
  font-weight: bold;
}

.labelll {
  font-size: 14px;
  color: #1f2e39;
}

.custom-input {
  padding: 8px 12px;
  border: 1px solid #707070;
  border-radius: 4px;
  font-size: 14px;
  color: #1f2e39;
  width: 94%;
  display: flex;
  height: 40px;
  margin-bottom: 20px;
}

.react-calendar__month-view__weekdays__weekday {
  color: #5a93ed;
  font-size: 13px;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar {
  border: none !important;
  margin-left: -10px;
}

.react-calendar__month-view__days__day--weekend {
  color: inherit !important;
}

.react-calendar__tile--active {
  background: rgba(33, 214, 170, 0.5) !important;
  color: rgba(31, 46, 57, 1) !important;
}

.react-calendar__tile--now {
  background: white !important;
}

.react-calendar__tile--rangeStart {
  border-top-left-radius: 5px !important;
  /* Make top-left corner rounded */
  border-bottom-left-radius: 5px !important;
  /* Make bottom-left corner rounded */
  background: rgba(33, 214, 170, 1) !important;
}

.react-calendar__tile--rangeEnd {
  border-top-right-radius: 5px !important;
  /* Make top-right corner rounded */
  border-bottom-right-radius: 5px !important;
  /* Make bottom-right corner rounded */
  background: rgba(33, 214, 170, 1) !important;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: white !important;
}

.react-calendar__tile--range {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.align-items-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 820px) {
  .search-containerss {
    display: flex;
    align-items: center;
  }

  .search-containerss input[type="text"] {
    padding: 9.5px;
    border: none;
    background-color: white;
    border-radius: 5px 0px 0px 5px;
    width: 420px;
    box-shadow: 0px 0px 20px #5b93ed33;
  }
  .search-containerss input[type="text"]:focus {
    outline: none;
    border: none;
  }

  .search-containerss button {
    background-color: #21d6aa;
    border: none;
    outline: none;
    border-radius: 0 5px 5px 0;
    padding: 14px;
    cursor: pointer;
  }
  .search-containerss button:focus {
    outline: none;
  }

  .search-containerss button img {
    width: 20px;
  }

  .search-containerss input[type="text"]::placeholder {
    color: #c6d7e3;
  }
}
