.analyst-head {
  color: #1f2e39;
  font-size: 28px;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 10px;
}

.analyst {
  margin-left: 20px;
}

/* Error message container */
.custom-error-container {
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: none;
  background-color: #ffe6e6;
  padding: 10px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  text-align: left;
  border-left:5px solid #f44336;
}

/* Error description text */
.custom-error-text {
  font-size: 16px;
  color: #555;
}
