@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

* {
  font-family: "Rubik", sans-serif;
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  box-sizing: border-box;
}

.head-title {
  background: url(/public/images/Green-Box.svg);
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-repeat: no-repeat;
  border-radius: 5px;
  align-items: center;
  background-size: cover;
  padding: 9px 20px;
  margin-top: 3px;
  margin-right: 10px;
  margin-left: 10px;
  box-sizing: content-box;
}

.pro {
  font-size: 18px;
  font-weight: bold;
}

.toggle-button {
  position: absolute;
 
  right: 30px;
  cursor: pointer;
  background-color: #f0f0f0;
  border: none;
  padding: 5px;
  border-radius: 3px;
}

@media (min-width: 1280px) and (min-height: 720px) {
  .head-title {
    background: url(/public/images/Green-Box.svg);
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-repeat: no-repeat;
    border-radius: 5px;
    align-items: center;
    background-size: cover;
    padding: 9px 20px;
    margin-right: 10px;
    margin-left: 10px;
  }
}

.custom-select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white
    url('data:image/svg+xml;utf8,<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M12 16l-6-6h12z" fill="%2390ee90"/></svg>')
    no-repeat right 10px center;
  background-size: 12px 12px; /* Adjust size as needed */
}
