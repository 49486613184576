.ql-container {
  font-family: Arial, sans-serif;
  font-size: 16px;
  min-height: 180px;
  max-height: 180px;
  background-color: #ffffff;
  border-radius: 0 0 8px 8px;
  overflow-y: auto; 
  scrollbar-width: thin;
}

/* Toolbar customization */
.ql-toolbar {
  background-color: #ffffff; /* Toolbar background */
  border: none; /* Remove border */
  border-radius: 8px 8px 0 0;
}
.ql-toolbar button {
  color: #fff; /* Icon color */
}

/* Placeholder text styling */
.ql-editor.ql-blank::before {
  color: #999999; /* Placeholder color */
  font-style: italic;
}

/* Editor text styling */
.ql-editor {
  padding: 15px;
  line-height: 1.6;
  color: #333333; /* Text color */
}

/* Style for headers */
.ql-editor h1 {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}
.ql-editor h2 {
  font-size: 20px;
  color: #555555;
}
.dropdown__list.opens {
  border-radius: 5px 5px 0 0;
  color: black;
  font-size: 15px;
}