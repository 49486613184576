.buttonbox {
  display: flex;
  width: 160px;
  height: 47px;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  justify-content: space-between;
  border: linear-gradient(to bottom left, #22d6aa 50%, #1dc59c 50%);
  background: linear-gradient(to bottom left, #22d6aa 50%, #1dc59c 50%);
  background-size: 150% 100%;
  background-position: 100% 0;
  clip-path: polygon(
    0 12px,
    12px 0,
    100% 0,
    100% calc(100% - 12px),
    calc(100% - 12px) 100%,
    0 100%
  );
  transition: all 0.3s ease;
}
.buttonbox:hover {
  background-size: -150% -100%;
  background: linear-gradient(
    to bottom left,
    #22d6aa 0%,
    #22d6aa 50%,
    #22d6aa 50%,
    #22d6aa 100%
  );
  background-position: 100% 0;
}
.backbutton {
  color: #1f2e39;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.iconbox {
  transition: transform 0.3s ease; /* Apply transition effect to icon */
}
.buttonbox:hover .iconbox {
  transform: rotate(45deg); /* Rotate icon on hover */
}
